import { register } from "@common/redux/auth/actions";
import { auth } from "firebase";

export const authMessages = {
    welcome: {
        key: 'auth.labelsAndTitles.WELCOME_BACK',
    },
    letsBegin: {
        key: 'auth.labelsAndTitles.LETS_BEGIN',
    },
    dontHaveAcc: {
        key: 'auth.labelsAndTitles.DONT_HAVE_ACC',
    },
    signUp: {
        key: 'auth.actions.SING_UP',
    },
    emailPlaceholder: {
        key: 'auth.labelsAndTitles.EMAIL_PLACE_HOLDER',
    },
    passwordBlank: {
        key: 'auth.messages.PASSWORD_ERROR',
    },
    forgotPassword: {
        key: 'auth.actions.FORGOT_PASSWORD',
    },
    signInTitle: {
        key: 'auth.actions.SIGN_IN',
    },
    passwordPlaceholder: {
        key: 'auth.labelsAndTitles.PASSWORD',
    },
    emailError: {
        key: 'auth.messages.ERROR_EMAIL'
    },
    passwordError: {
        key: 'auth.messages.ERROR_PASSWORD'
    },
    loginError: {
        key: 'auth.messages.API_ERROR'
    },
    newPasswordError: {
        key: 'auth.messages.NEW_PASSWORD_ERROR',
    },
    newPasswordErrorRegex: {
        key: 'auth.messages.NEW_PASSWORD_ERROR_REGEX',
    },
    confirmPasswordError: {
        key: 'auth.messages.CONFIRM_PASSWORD_ERROR',
    },
    passwordNotMatched: {
        key: 'auth.messages.PASSWORD_NOT_MATCHED',
    },
    newPassword: {
        key: 'auth.labelsAndTitles.NEW_PASSWORD',
    },
    confirmPassword: {
        key: 'auth.labelsAndTitles.CONFIRM_PASSWORD',
    },
    setPassword: {
        key: 'auth.actions.SET_PASSWORD',
    },
    createPassword: {
        key: 'auth.labelsAndTitles.CREATE_PASSWORD',
    },
    createPasswordSubtext: {
        key: 'auth.messages.CREATE_PASSWORD_SUBTEXT',
    },
    regSubtext: {
        key: 'auth.messages.SUBTEXT',
    },
    firstNameMsg: {
        key: 'auth.messages.FIRST_NAME_MSG',
    },
    lastNameMsg: {
        key: 'auth.messages.LAST_NAME_MSG',
    },
    regTitle: {
        key: 'auth.labelsAndTitles.REGISTER_USER',
    },
    firstName: {
        key: 'auth.labelsAndTitles.FIRST_NAME',
    },
    lastName: {
        key: 'auth.labelsAndTitles.LAST_NAME',
    },
    selectLang: {
        key: 'auth.labelsAndTitles.SELECT_LANGUAGE',
    },
    language: {
        key: 'auth.labelsAndTitles.LANGUAGE',
    },
    email: {
        key: 'auth.labelsAndTitles.EMAIL',
    },
    submit: {
        key: 'auth.actions.SUBMIT',
    },
    country: {
        key: 'auth.labelsAndTitles.COUNTRY',
    },
    startTyping: {
        key: 'auth.messages.LOCATION_SEARCH_PLACEHOLDER'
    },
    enrollCohort: {
        key: 'auth.messages.JOIN_BATCH_SUCCESS'
    },
    enrollTitle: {
        key: 'auth.labelsAndTitles.ENROLL_TITLE'
    },
    enrollSubtext: {
        key: 'auth.labelsAndTitles.ENROLL_SUBTEXT'
    },
    cohortUuid: {
        key: 'auth.labelsAndTitles.COHORT_UUID'
    },
    enroll: {
        key: 'auth.actions.ENROLL'
    },
    abort: {
        key: 'auth.actions.ABORT'
    },
    forgotPasswordSuccess: {
        key: 'auth.messages.FORGOT_PASSWORD_SUCCESS_ALERT'
    },
    resetPassword: {
        key: 'auth.actions.RESET_PASSWORD'
    },
    emailNotExist: {
        key: 'auth.messages.EMAIL_NOT_EXISTS',
    },
    resetPasswordSuccess: {
        key: 'auth.messages.PASSWORD_UPDATE_SUCCESSFUL'
    },
    linkExpired: {
        key: 'auth.messages.LINK_EXPIRED'
    },
    requestNewLink: {
        key: 'auth.actions.REQUEST_NEW_LINK'
    },
    termsAndConditionText: {
        key: 'global.messages.TERMS_AND_CONDITIONS',
    },
    termsUrl: {
        key: 'global.links.TERMS_URL',
    },
    and: {
        key: 'global.labelsAndTitles.AND',
    },
    privacyPolicyUrl: {
        key: 'global.links.PRIVACY_POLICY_URL',
    },
    terms: {
        key: 'global.labelsAndTitles.TERMS',
    },
    privacyPolicy: {
        key: 'global.labelsAndTitles.PRIVACY_POLICY',
    },
    location: {
        key: 'profile.labelsAndTitles.LOCATION'
    },
    yearOfBirth: {
        key: 'profile.labelsAndTitles.YEAR_OF_BIRTH'
    },
    gender: {
        key: 'profile.labelsAndTitles.GENDER'
    },
    education: {
        key: 'profile.labelsAndTitles.EDUCATION'
    },
    verifyText: {
        key: 'register.verifyText'
    },
    noEmail: {
        key: 'register.noEmail'
    },
    resendEmail: {
        key: 'register.resendEmail'
    },
    verifyEmail: {
        key: 'register.verifyEmail'
    },
    createAccount: {
        key: 'register.createAccount'
    },
    accountAlready: {
        key: 'register.accountAlready'
    },
    signInText: {
        key: 'register.signInText'
    },
    userExist: {
        key: 'register.userExist'
    },
    createBtn: {
        key: 'register.createBtn'
    },
    confirmPasswordSignUp: {
        key: 'register.confirmPasswordSignUp'
    },
    resendSuccessAlert: {
        key: 'register.resendSuccessAlert'
    },
    userVerifySuccess: {
        key: 'register.userVerifySuccess'
    },
    verify: {
        key: 'register.verify'
    },
    next: {
        key: 'register.next'
    },
    verifyAgain: {
        key: 'auth.actions.VERIFY_AGAIN'
    },
    emailVerification: {
        key: 'auth.labelsAndTitles.EMAIL_VERIFICATION'
    },
    emailVerified: {
        key: 'auth.labelsAndTitles.EMAIL_VERIFIED'
    },
    emailVerificationError: {
        key: 'auth.messages.EMAIL_VERIFICATION_ERROR'
    },
    emailVerificationSuccess: {
        key: 'auth.messages.EMAIL_VERIFICATION_SUCCESS'
    },
    troubleLogging: {
        key: 'auth.messages.TROUBLE_LOGGING'
    },
    emailAlreadyVerified: {
        key: 'auth.labelsAndTitles.EMAIL_ALREADY_VERIFIED'
    },
    pleaseSignIn: {
        key: 'auth.labelsAndTitles.PLEASE_SIGN_IN'
    },
    toProceed: {
        key: 'auth.labelsAndTitles.TO_PROCEED'
    },
    emailVerificationMessage: {
        key: 'auth.labelsAndTitles.EMAIL_VERIFICATION_MESSAGE'
    },
    postVerification: {
        key: 'register.postVerification'
    },
    male: {
        key: 'global.labelsAndTitles.MALE'
    },
    female: {
        key: 'global.labelsAndTitles.FEMALE'
    },
    others: {
        key: 'global.labelsAndTitles.OTHERS'
    },
    theEmail: {
        key: 'auth.messages.THE_EMAIL'
    },
    alreadyRegistered: {
        key: 'auth.messages.ALREADY_REGISTERED'
    },
    notRegistered: {
        key: 'auth.messages.NOT_REGISTERED'
    },
    signInContinue: {
        key: 'auth.actions.SIGN_IN_CONTINUE'
    },
    signUpContinue: {
        key: 'auth.actions.SIGN_UP_CONTINUE'
    },
    signInGoogle: {
        key: 'auth.actions.SIGN_IN_GOOGLE'
    },
    signUpGoogle: {
        key: 'auth.actions.SIGN_UP_GOOGLE'
    },
    googleSignUpDescription: {
        key: 'auth.messages.GOOGLE_SIGN_UP_DESCRIPTION'
    },
    or: {
        key: 'global.actions.OR'
    },
    cityNotFound: {
        key: 'register.LocationNotFound'
    },
    enterCityName: {
        key: 'register.label.ENTER_CITY_NAME'
    },
    selectCity: {
        key: 'register.label.SELECT_CITY'
    },
    mobileNumber: {
        key: 'register.label.MOBILE_NUMBER'
    },
    selectCountry: {
        key: 'register.label.SELECT_COUNTRY'
    },
    noResultFound: {
        key: 'messages.messages.NO_SEARCH_RESULT_MESSAGE'
    },
    countryNotSelected:{
        key: "register.COUNTRY_NOT_SELECTED"
    },
    accept: {
        key: "register.label.ACCEPT"
    },
    deny: {
        key: "register.label.DENY"
    },
    conscent: {
        key: "register.label.CONSCENT"
    },
    authorise: {
        key: "register.label.AUTHORISE"
      },
    
};
