/**
 * Commented below code as we are not yet supporting mutlilang
 */
import { WebStoreProviderService } from '@store/WebStoreProviderService';
import { Logger } from '@utils/Logger';
import { LocalStorage, StorageKeys } from '@utils/LocalStorage';
import { i18n } from './i18n';

export enum AppLanguage {
  English = 'en',
  Spanish = 'es',
  Portuguese = 'pt',
  EnglishUS = 'en-US',
  Hindi = 'hi',
  Bahasa = 'ba',
  Filipino = 'fil',
  Kannada = 'kn',
  Bangla = 'bn',
  Odia = 'or'
}

const store = WebStoreProviderService.getStore();

// Get the isAuthenticated from store
const isAuthenticated = () => true;

export const guestUserLangCode = () => {
    const guestLang: {
    id: string | number;
    code: string;
  } = JSON.parse(localStorage.getItem(StorageKeys.GUEST_USER_LANG));
    return guestLang !== null && !guestLang ? guestLang.code : 'en';
};

export const getUserSettingsFromLocalStorage = async () => {
    const userSettings: any = await LocalStorage.get(StorageKeys.USER_SETTINGS);
    return (!!userSettings) ? userSettings : undefined;
};

export const userPrimeLangCode = () => {
    const isLoggedIn = isAuthenticated();
    if (isLoggedIn) {
    // const primaryLang = getFromStore();
        const primaryLang = { languageCode: 'en' };
        Logger.info('[AppLanguage - userPrimeLangCode]', { primaryLang });
        return primaryLang ? primaryLang.languageCode : '';
    }
};

export const getLangPath = (actualRoute: string) => {
    const lang = i18n.language;
    const langCode = lang || 'en';
    return `/${langCode}${actualRoute}`;
};

export enum AppLanguageTranslationFile {
  English = 'https://witp1.blob.core.windows.net/locale-files/en.json',
  Portuguese = 'https://witp1.blob.core.windows.net/locale-files/pt.json',
  EnglishUS = 'https://witp1.blob.core.windows.net/locale-files/en-US.json',
  Spanish = 'https://witp1.blob.core.windows.net/locale-files/es.json',
  Hindi = 'https://witp1.blob.core.windows.net/locale-files/hi.json',
  Bahasa = 'https://witp1.blob.core.windows.net/locale-files/ba.json',
  Filipino = 'https://witp1.blob.core.windows.net/locale-files/fil.json',
  Kannada = 'https://witp1.blob.core.windows.net/locale-files/kn.json',
  Bangla = 'https://witp1.blob.core.windows.net/locale-files/bn.json',
  Odia = 'https://witp1.blob.core.windows.net/locale-files/or.json'
}
